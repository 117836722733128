// Example starter JavaScript for disabling form submissions if there are invalid fields
(function() {
	'use strict';
	window.addEventListener('load', function() {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		var forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function(form) {
			form.addEventListener('submit', function(event) {
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			}, false);
		});
	}, false);
})();



$(document).ready(function(){
	$('.recaptchaform').on('submit', function (e) {

		if (!this.checkValidity || this.checkValidity()) {
			grecaptcha.execute();
		} else {
			e.preventDefault();
		}

		e.preventDefault();
	});

	$('.file-upload input[type="file"]').change(function(){
		var list_class = 'files-' + $(this).attr('id');
		var input = this;

		if (input.files) {
	        for (var i = input.files.length - 1; i >= 0; i--) {
	        	$('.'+list_class).append('<li>'+input.files[i].name+'</li>');
	        }
	    }
	});

});