// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{

	/*
	 *	FULLSCREEN SLIDER
	 */

	$fullscreen_slider = $('#fullscreen-slider');

	$fullscreen_slider.slick({
		autoplay: true,
		arrows: false,
		autoplaySpeed: 3000,
		fade: true,
	});



	/*
	 *	GO DOWN
	 */

	$('#go-down').click(function(e){
		e.preventDefault();

		$('body, html').animate({
			'scrollTop': $(this).offset().top + $(this).outerHeight() + 'px'
		}, 400);
	});



	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init();
});



/*
 *	PROJECT SLIDER
 */

var kinetic_scrollblock = false;
var kinetic_timer = null;

$(window).on('load', function ()
{
	$project_slider = $('#project-slider');

	$project_slider.slick({
		mobileFirst: true,
		vertical: true,
		verticalSwiping: true,
		adaptiveHeight: false,
		adaptiveWidth: false,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-up"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-down"></i></button>',
	});

	var sliding = false;

	$project_slider.on('beforeChange', function(event, slick) {
		sliding = true;
	});

	$project_slider.on('afterChange', function(event, slick) {
		sliding = false;
	});

	$project_slider.on('wheel', (function(e) {
		e.preventDefault();

		if (!sliding && !kinetic_scrollblock) {
			sliding = true;

			if (e.originalEvent.deltaY > 0) {
				$(this).slick('slickNext');
			} else {
				$(this).slick('slickPrev');
			}

			kinetic_scrollblock = true;
			clearTimeout(kinetic_timer);
			kinetic_timer = setTimeout(function(){
				kinetic_scrollblock = false;
			}, 1400);
		}
	}));


	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});





// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}
